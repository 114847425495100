import logo from "./logo.svg";
import "./App.css";
import Timer from "./Countdown/Timer";
import "./styles.css";
import { motion } from "framer-motion";

function App() {
  return (
    <div className="App">
      <h2
        style={{
          position: "absolute",
          top: "10px",
          right: "40px",
          fontSize: "20px",
        }}
      >
        <a href="https://beta.see-note.com" rel="noreferrer">
          beta member sign in
        </a>
      </h2>
      <div className="container"        style={{ paddingTop:"70px"
        }}>
      <motion.div className="container" 
      initial={{ scale: 0 }}
    animate={{ rotate: 360, scale: 1 }}
    transition={{
      type: "spring",
      stiffness: 260,
      damping: 20
    }}>
        <img
          height="200px"
          width="200px"
          src={logo}
          className="App-logo"
          alt="logo"
        />
              </motion.div>
        <h2 style={{ fontWeight: "100" }}>coming soon.
        </h2>
        <Timer />
      </div>
    </div>
  );
}

export default App;
